/* Font-Awesome icons */
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");



/* Lora & OpenSans font family */
@font-face {
	font-family: "Lora-Bold";
	src: local("Lora"),
	  url("./assets/fonts/Lora/static/Lora-Bold.ttf") format("truetype");
	font-weight: bolder;
}

@font-face {
  font-family: "Lora-SemiBold-Italic";
  src: local("Lora"),
    url("./assets/fonts/Lora/static/Lora-SemiBoldItalic.ttf") format("truetype");
  font-weight: bolder;
}

@font-face {
	font-family: "OpenSans-Light";
	src: local("OpenSans-Light"),
	  url("./assets/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
	font-size: 10px;
  }

  @font-face {
    font-family: "OpenSans-Bold";
    src: local("OpenSans-Bold"),
      url("./assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
    font-weight: bold;
    }

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: bold;
} 





/* removes up down arrow for number input  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* removes outline for inputs */
textarea:focus, input:focus{
  outline: none;
}

.search-input:hover {
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2); 
}



.logo {
  font-size: 36px; 
  font-family: "Lora-SemiBold-Italic";
  margin-left: 0%;
}

.headline {
  font-size: 56px; 
  font-family: "Lora-Bold";
  text-align: center;
}


.subject {
  font-size: 24px; 
  font-family: "OpenSans-Regular";
  text-align: center;
  margin-bottom: 40px;
}

.label {
  font-weight: bold;
  font-size: 24px;
  font-family: "Lora-SemiBold-Italic";
}


.copyright {
  font-size: 12px; 
  font-family: "OpenSans-Light";
}



.card {
  background-color: white;
  display: flex; 
  margin-bottom: 14px; 
  border-radius: 10px;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2); 
  cursor: pointer;
  text-decoration: none;
  color: black;
}


.card:hover {
  background-color: white;
  display: flex; 
  margin-bottom: 14px; 
  border-radius: 10px;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1.5px black;
  border-style: solid;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2); 
  cursor: pointer;
  text-decoration: none;
  color: black;
}
